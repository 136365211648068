import React from "react";
import styled from "styled-components";

import Cta from "../UI/shared/ui-components/cta/Cta";
import Footer from "../UI/shared/ui-components/footer/Footer";
import Header from "../UI/shared/ui-components/functional/header/Header";
import General from "../UI/shared/ui-components/layout/General";
import Grid from "../UI/shared/ui-components/layout/Grid";
import PageCover from "../UI/shared/ui-components/layout/PageCover";
import P from "../UI/shared/ui-components/presentational/P";
import H2 from "../UI/shared/ui-components/presentational/H2";
import ContactForm from "../UI/contact/ContactForm";
import SEO from "../UI/shared/ui-components/functional/seo/SEO";

const contact = () => {
  return (
    <General>
      <SEO title="Contact | App laten maken Antwerpen | 5bars"></SEO>
      <Header></Header>
      <PageCover
        title="Contact"
        subTitle="Heb je nog vragen of ben je al helemaal overtuigd om samen te werken? Stuur ons gerust een mailtje via het formulier en we nemen zo snel mogelijk contact met je op!"
        leftColComponent={() => (
          <div>
            <H2>Bel of mail ons</H2>
            <Divider></Divider>
            <P>
              <strong>Adres:</strong> Provinciestraat 52, 2018 Antwerpen
            </P>
            <P>
              <strong>Tel:</strong> +32 476 07 56 71
            </P>
            <P>
              <strong>E-mail:</strong>{" "}
              <a href="mailto:info@5bars.be">info@5bars.be</a>
            </P>
          </div>
        )}
        rightColComponent={() => <ContactForm></ContactForm>}
      ></PageCover>
      <Grid>
        <Cta></Cta>
      </Grid>
      <Footer></Footer>
    </General>
  );
};

export default contact;

const Divider = styled.div`
  width: 15px;
  height: 3px;
  background-color: ${(props) => props.theme.primaryDefault};
  margin-bottom: 2rem;
`;
