import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Header from "../UI/shared/ui-components/functional/header/Header";
import H2 from "../UI/shared/ui-components/presentational/H2";
import General from "../UI/shared/ui-components/layout/General";
import PageCover from "../UI/shared/ui-components/layout/PageCover";
import Cta from "../UI/shared/ui-components/cta/Cta";
import Footer from "../UI/shared/ui-components/footer/Footer";
import Grid from "../UI/shared/ui-components/layout/Grid";
import CasesList from "../UI/cases/CasesList";
import SEO from "../UI/shared/ui-components/functional/seo/SEO";

const cases = ({ data }) => {
  return (
    <General>
      <SEO title="Cases | App laten maken Antwerpen | 5bars"></SEO>
      <Header></Header>
      <PageCover
        title="Cases"
        subTitle="Benieuwd naar onze realisaties? We wonnen ondertussen al heel wat tijd terug voor onze klanten. Hier vind je enkele van onze succes verhalen."
        leftColComponent={() => (
          <StyledH2>
            “We zijn trots op wat we doen voor onze klanten: daarom zetten we ze
            hier graag even in de kijker”
          </StyledH2>
        )}
        rightColComponent={() => (
          <Image
            image={data.sanityImages.mainImage.asset.gatsbyImageData}
            alt="Smartphone op een houder voor een Imac scherm"
          ></Image>
        )}
      ></PageCover>
      <CasesList></CasesList>
      <Grid>
        <Cta></Cta>
      </Grid>
      <Footer></Footer>
    </General>
  );
};

export default cases;

export const query = graphql`
  query CasesImageQuery {
    sanityImages(_id: { eq: "4ab18a92-1011-4259-9176-be61e32e6286" }) {
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;

const StyledH2 = styled(H2)`
  width: 450px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 500px;

  @media only screen and (max-width: 1000px) {
    height: 250px;
  }
`;
