import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Logo from "../../../../svg/logo.svg";
import P from "../presentational/P";
import Grid from "../layout/Grid";
import { useMediaQuery } from "@react-hook/media-query";

interface Props {}

const Footer = (props: Props) => {
  const mobileQueryMatch = useMediaQuery("only screen and (max-width: 800px)");

  return (
    <Grid>
      <FooterContainer>
        <Left>
          <HighBar></HighBar>
          <TextBarLeft>
            <P>Provinciestraat 52</P>
            <P>2018 Antwerpen</P>
            <LowBar></LowBar>
          </TextBarLeft>
        </Left>

        <Center>
          <StyledLogo></StyledLogo>
          <CenterLinks>
            <StyledP>© {new Date().getFullYear()} - 5bars</StyledP>
            {mobileQueryMatch ? null : (
              <>
                <StyledA to="privacy-policy">Privacy policy</StyledA>
                <StyledA to="terms-and-conditions">
                  Algemene voorwaarden
                </StyledA>
              </>
            )}
          </CenterLinks>
        </Center>
        <Right>
          <TextBarRight>
            <StyledAnchor href="mailto:info@5bars.be">
              info@5bars.be
            </StyledAnchor>
            <StyledAnchor href="tel:+32476075671">
              +32 476 07 56 71
            </StyledAnchor>
            <LowBar></LowBar>
          </TextBarRight>
          <HighBar></HighBar>
        </Right>
      </FooterContainer>
    </Grid>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2rem;

  @media only screen and (max-width: 800px) {
    padding-top: 1rem;
  }
`;

const StyledLogo = styled(Logo)`
  fill: ${(props) => props.theme.primaryDefault};
  height: 7rem;
  width: auto;

  @media only screen and (max-width: 700px) {
    height: 4rem;
  }
`;

const Left = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  @media only screen and (max-width: 800px) {
    & p {
      font-size: 0.6rem !important;
    }
  }
`;

const TextBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextBarLeft = styled(TextBarContainer)`
  margin-left: 20px;

  & p {
    color: ${(props) => props.theme.primaryDefault} !important;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 10px;
  }
`;

const TextBarRight = styled(TextBarContainer)`
  text-align: right;
  margin-right: 20px;
  align-items: flex-end;

  @media only screen and (max-width: 800px) {
    margin-right: 10px;
  }
`;

const Right = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`;

const CenterLinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledP = styled.p`
  color: ${(props) => props.theme.primaryDefault};
  font-weight: bold;
  margin: 0 1rem;

  @media only screen and (max-width: 800px) {
    font-size: 0.6rem;
  }
`;

const StyledA = styled(Link)`
  color: ${(props) => props.theme.primaryDefault};
  font-weight: bold;
  text-decoration: none;
  margin: 0 1rem;
`;

const StyledAnchor = styled.a`
  color: ${(props) => props.theme.primaryDefault};
  text-decoration: none;
  margin: 0.3rem 0;

  @media only screen and (max-width: 800px) {
    font-size: 0.6rem;
  }
`;

const Bar = styled.div`
  background-color: ${(props) => props.theme.primaryDefault};
  width: 25px;

  @media only screen and (max-width: 800px) {
    width: 15px;
  }
`;

const HighBar = styled(Bar)`
  height: 130px;

  @media only screen and (max-width: 800px) {
    height: 80px;
  }
`;

const LowBar = styled(Bar)`
  margin-top: 20px;
  height: 40px;

  @media only screen and (max-width: 800px) {
    margin-top: 10px;
    height: 20px;
  }
`;
