import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";

import Button from "../shared/ui-components/functional/form/Button";
import Grid from "../shared/ui-components/layout/Grid";
import H2 from "../shared/ui-components/presentational/H2";
import P from "../shared/ui-components/presentational/P";

interface Props {}

const Philosophy = ({}: Props) => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      sanityImages(_id: { eq: "8ad07c52-29e0-4cd5-878a-92649f124071" }) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const image = getImage(data.sanityImages.mainImage.asset.gatsbyImageData);
  const bgImage = convertToBgImage(image);

  return (
    <StyledPhilosophySection id="philosophy">
      <Grid>
        <StyledBgImage {...bgImage} preserveStackingContext>
          <StyledContainer>
            <H2>
              5bars ontwikkelt <br />
              zodat u kunt ontwikkelen
            </H2>
            <P>
              Bij 5bars draait het allemaal om tijdswinst. Elke ondernemer heeft
              terugkerende taken die veel tijd kosten maar perfect
              geautomatiseerd kunnen worden. Wij gaan op zoek naar deze
              processen en komen met innovatieve oplossingen. Dat kan door
              externe services te koppelen aan jouw bestaande systemen, of met
              volledig custom ontwikkelde applicaties.
            </P>
            <P>
              Of het nu complexe websites en webshops, 3D configurators of
              mobiele applicaties zijn. Voor ons is het belangrijk dat het voor
              jou tijd terug wint.
            </P>
            <StyledLink to="/contact">
              <Button typification="bordered" white>
                Contacteer ons
              </Button>
            </StyledLink>
          </StyledContainer>
        </StyledBgImage>
      </Grid>
    </StyledPhilosophySection>
  );
};

export default Philosophy;

const StyledPhilosophySection = styled.section``;

const StyledContainer = styled.div`
  padding: 4rem;
  & h2 {
    color: #fff;
    margin-bottom: 1rem;
  }

  & p {
    color: #fff;
    margin-bottom: 1rem;

    &:nth-child(3) {
      margin-bottom: 2rem;
    }
  }

  max-width: 700px;

  @media only screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const StyledBgImage = styled(BackgroundImage)`
  &:before {
    background: ${(props) => props.theme.primaryDefault};
    opacity: 0.99 !important;
    background-image: none !important;
    mix-blend-mode: multiply;
    backdrop-filter: blur(5px) !important;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;
