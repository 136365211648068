import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import P from "../../presentational/P";

interface CasePropTypes {
  color: string;
  image: any;
  description: string;
  slug: string;
  customer: string;
}

const Case = ({ color, image, description, slug, customer }: CasePropTypes) => {
  return (
    <CaseContainer to={`/cases/${slug}`}>
      <ImageContainer>
        <NavigateMessage color={color}>
          <P>Bekijk de case</P>
        </NavigateMessage>
        <StyledImage
          image={image}
          alt={`image about ${customer} app`}
        ></StyledImage>
      </ImageContainer>
      <TextContainer>
        <P>{customer}</P>
        <h3>{description}</h3>
      </TextContainer>
    </CaseContainer>
  );
};

const CaseContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;

  &:hover {
    .gatsby-image-wrapper {
      height: calc(100% - 2rem);
      width: calc(100% - 2rem);
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.15);
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 420px;
  width: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  transition: height 0.3s ease, width 0.3s ease, box-shadow 0.3s ease;
`;

const TextContainer = styled.div`
  height: 100px;
  max-height: 100px;
  h3 {
    font-size: 1.4rem;
    margin: 0;
    color: ${(props) => props.theme.fontColor};
  }
  p {
    font-size: 0.85rem;
    margin: 0.6rem 0;
    margin-top: 0.85rem;
  }
`;

const NavigateMessage = styled.div<{ color: string }>`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  background-color: ${(props) => props.color};
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.5rem;

  p {
    margin: 0;
    color: #fff;
    font-weight: bold;
  }
`;

export default Case;
