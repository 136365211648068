import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Grid from "../../shared/ui-components/layout/Grid";
import H2 from "../../shared/ui-components/presentational/H2";
import Testimonial from "./Testimonial";

interface Props {}

const Testimonials = (props: Props) => {
  const { allSanityTestimonial, sanityImages } = useStaticQuery(graphql`
    query TestimonialQuery {
      allSanityTestimonial {
        nodes {
          clientName
          quote
          testimonial
        }
      }
      sanityImages(_id: { eq: "7fceb823-c55f-47a2-9234-686a3a4761ad" }) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setTestimonials(allSanityTestimonial.nodes);
  }, [allSanityTestimonial]);

  const [activeTestimonial, setActiveTestimonial] = useState(0);
  return (
    <StyledSection>
      <StyledGrid>
        <H2>Geloof ons niet op ons woord: neem het aan van onze klanten</H2>
        <TestimonialContainer>
          {testimonials.length && (
            <Testimonial
              testimonial={{
                name: testimonials[activeTestimonial]?.clientName,
                quote: testimonials[activeTestimonial]?.quote,
                testimonial: testimonials[activeTestimonial]?.testimonial,
              }}
              onFinishTimer={() => {
                if (activeTestimonial === testimonials.length - 1) {
                  setActiveTestimonial(0);
                  return;
                }
                setActiveTestimonial(
                  (activeTestimonial) => activeTestimonial + 1
                );
              }}
            ></Testimonial>
          )}
          <Image
            image={sanityImages.mainImage.asset.gatsbyImageData}
            alt="client logos"
          ></Image>
        </TestimonialContainer>
      </StyledGrid>
    </StyledSection>
  );
};

export default Testimonials;

const StyledSection = styled.section`
  text-align: center;
  ${(props) => props.theme.rowPadding};
`;

const StyledGrid = styled(Grid)`
  position: relative;

  & h2 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const TestimonialContainer = styled.div`
  ${(props) => props.theme.rowPadding};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const Image = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0) 33%,
      rgba(255, 255, 255, 0) 66%,
      rgba(255, 255, 255, 1)
    );
  }
`;
