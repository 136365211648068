import styled from "styled-components";

const H2 = styled.h2`
  color: ${(props) => props.theme.fontColor};
  font-size: 2rem;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export default H2;
