import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";

import Grid from "../../shared/ui-components/layout/Grid";
import H2 from "../../shared/ui-components/presentational/H2";
import Case from "../../shared/ui-components/singles/case/Case";
import Button from "../../shared/ui-components/functional/form/Button";

interface Props {}

const Cases = (props: Props) => {
  const { allSanityCase } = useStaticQuery(graphql`
    query CasesQuery {
      allSanityCase(limit: 3) {
        nodes {
          color
          shortText
          title
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Grid>
        <StyledH2>Cases</StyledH2>
        <CasesRow>
          {allSanityCase.nodes.map((cs, i) => (
            <Case
              key={i}
              color={cs.color}
              image={cs.mainImage.asset.gatsbyImageData}
              description={cs.shortText}
              slug={cs.slug.current}
              customer={cs.title}
            ></Case>
          ))}
        </CasesRow>
        <CaseCta>
          <StyledH2>
            Nog niet overtuigd?
            <br />
            Bekijk al onze cases
          </StyledH2>
          <StyledLink to="cases">
            <Button typification="bordered">Alle cases</Button>
          </StyledLink>
        </CaseCta>
      </Grid>
    </Section>
  );
};

export default Cases;

const Section = styled.section`
  ${(props) => props.theme.rowPadding};

  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CasesRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  padding: 0 1rem;
`;

const CaseCta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;
