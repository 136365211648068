import { useForm } from "@formspree/react";
import React, { useState } from "react";
import styled from "styled-components";
import BounceLoader from "react-spinners/BounceLoader";

import Button from "../functional/form/Button";
import Row from "../layout/Row";

interface Props {}

const Cta = (props: Props) => {
  const [email, setEmail] = useState("");
  const [state, handleSubmit] = useForm("mayaqwll");

  return (
    <StyledCtaContainer>
      <ContentContainer onSubmit={handleSubmit}>
        <h2>Ik wil meer tijd!</h2>
        <p>
          Benieuwd hoe we voor jou tijd kunnen winnen? Laat je email adres hier
          achter en wij contacteren je voor een eerste kennismakingsgesprek.
        </p>
        <Strong>Contacteer me</Strong>
        <FormRow>
          {state.submitting ? (
            <BounceLoader color={"#fff"} size={20}></BounceLoader>
          ) : state.succeeded ? (
            <p>We hebben je bericht goed ontvangen! 🚀</p>
          ) : (
            <StyledRow gap={1} pos={"space-between"}>
              <StyledInput
                placeholder="E-mail"
                white
                fullWidth
                id="email"
                name="email"
              ></StyledInput>
              <Button type="submit" white>
                Mail mij
              </Button>
            </StyledRow>
          )}
        </FormRow>
      </ContentContainer>
    </StyledCtaContainer>
  );
};

export default Cta;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const StyledCtaContainer = styled.section`
  background: radial-gradient(
    ellipse at bottom left,
    #2d0ad2 30%,
    #18056d 62%,
    #430569 94%
  );
  padding: 5rem 1rem;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 6px 6px 99px rgba(45, 10, 210, 0.27);

  h2 {
    margin: 0;
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 4rem 1rem;

    p {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ContentContainer = styled.form`
  width: 500px;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const Strong = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input<{ white: boolean; fullWidth: boolean }>`
  border: 1px solid black;
  ${(props) => props.white && `border-color: #fff; color: #fff;`};
  background: transparent;
  padding: 0.7rem 0.8rem;
  ${(props) => props.fullWidth && "flex: 1;"};

  &::placeholder {
    ${(props) => props.white && `color: #fff`};
    opacity: 0.4;
  }
`;
const FormRow = styled.div`
  height: 40px;

  @media only screen and (max-width: 500px) {
    height: auto;
  }
`;
