import styled from "styled-components";

const Row = styled.div<{
  pos: "flex-start" | "flex-end" | "center" | "space-between";
  gap: number;
}>`
  display: flex;
  ${(props) => props.pos && `justify-content: ${props.pos}`};
  align-items: center;
  ${(props) => props.gap && `gap: ${props.gap}rem;`};

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export default Row;
