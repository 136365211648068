import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";

import Grid from "../../shared/ui-components/layout/Grid";
import H2 from "../../shared/ui-components/presentational/H2";
import P from "../../shared/ui-components/presentational/P";
import PointCanvas from "./pointAnimation/PointCanvas";

interface Props {}

const Expertise = (props: Props) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      web: sanityImages(_id: { eq: "e844d5d9-19b0-46dc-9ca0-07df044b24dd" }) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      mobile: sanityImages(
        _id: { eq: "f9afa980-36c5-4c56-9d45-3fadeea35a24" }
      ) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const webImage = getImage(data.web.mainImage.asset.gatsbyImageData);
  const mobileImage = getImage(data.mobile.mainImage.asset.gatsbyImageData);
  const bgWeb = convertToBgImage(webImage);
  const bgMobile = convertToBgImage(mobileImage);

  return (
    <StyledSection>
      <StyledGrid>
        <ExpertiseTextRow>
          <H2>
            We gebruiken technologie als oplossing voor jouw unieke probleem.
          </H2>
          <P>
            Onze ervaring als digitale partner staat ons toe de juiste
            technologie voor jouw probleem te kiezen. Daarom zijn we de
            geschikte keuze om een betaalbare, kwalitatieve applicatie te laten
            maken om jouw processen te automatiseren. Naast websites en webshops
            zijn we onder andere experts in onderstaande digitale oplossingen:
          </P>
        </ExpertiseTextRow>
        <ExpertiseBlockRow>
          <ExpertiseBlock
            img={bgWeb}
            text="Web Apps"
            slug="web"
          ></ExpertiseBlock>
          <ExpertiseBlock
            img={bgMobile}
            text="Mobiele Apps"
            slug="mobile"
          ></ExpertiseBlock>
          <ExpertiseBlock text="3D Configurators" slug="3d">
            <PointCanvas zoom={8}></PointCanvas>
          </ExpertiseBlock>
        </ExpertiseBlockRow>
      </StyledGrid>
    </StyledSection>
  );
};

export default Expertise;

const StyledSection = styled.section`
  ${(props) => props.theme.rowPadding};
`;

const ExpertiseTextRow = styled.div`
  display: flex;
  align-items: center;

  & h2 {
    width: calc(60% - 1rem);
    margin-right: 1rem;
  }

  & p {
    width: 40%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    & h2 {
      width: 100%;
      margin-right: 0rem;
    }

    & p {
      width: 100%;
    }
  }
`;

const StyledGrid = styled(Grid)`
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 0 1rem;
  }
`;

const ExpertiseBlockRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding-top: 4rem;
  grid-gap: 1rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

import BackgroundImage from "gatsby-background-image";

interface ExpertiseBlockPropTypes {
  img?: any;
  text: string;
  children?: any;
  slug: string;
}

const ExpertiseBlock = ({
  img = undefined,
  text,
  children,
  slug,
}: ExpertiseBlockPropTypes) => {
  return (
    <StyledLink to={`/expertise/#${slug}`}>
      {img && !children && (
        <StyledBgImage {...img} preserveStackingContext>
          <TitleContainer>
            <h3>{text}</h3>
          </TitleContainer>
        </StyledBgImage>
      )}
      <ChildrenContainer>
        {children && children}
        <h3>{text}</h3>
      </ChildrenContainer>
    </StyledLink>
  );
};

const StyledBgImage = styled(BackgroundImage)`
  min-height: 300px;
  height: 300px;
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const ChildrenContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;

  & h3 {
    position: absolute;
    color: #fff;
    font-weight: bold;
    margin: 0;
    font-size: 1.5rem;
    left: 1rem;
    bottom: 1rem;
  }
`;

const TitleContainer = styled.div`
  padding: 1rem;
  width: 100%;
  position: absolute;
  box-sizing: border-box;

  &:before {
    position: absolute;
    content: "";
    display: block;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  & h3 {
    color: #fff;
    font-weight: bold;
    margin: 0;
    font-size: 1.5rem;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-1%);
  }
`;
