import React, { useState } from "react";
import styled from "styled-components";
import Bar from "./Bar";

interface Props {}

const bars = [0, 1, 2, 3, 4];

const Bars = (props: Props) => {
  return (
    <BarContainer>
      {bars.map((bar, index) => (
        <Bar key={index} index={index}></Bar>
      ))}
    </BarContainer>
  );
};

export default Bars;

const BarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: calc(1200px / 9);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
