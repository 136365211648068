import React from "react";
import styled from "styled-components";
import { useForm } from "@formspree/react";
import BounceLoader from "react-spinners/BounceLoader";

import Button from "../shared/ui-components/functional/form/Button";
import H2 from "../shared/ui-components/presentational/H2";
import P from "../shared/ui-components/presentational/P";

interface Props {}

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xleadvqr");
  return (
    <FormContainer onSubmit={handleSubmit}>
      <H2>Stuur een bericht</H2>
      <Columns>
        <LeftCol>
          <div>
            <label htmlFor="name">Naam</label>
            <input
              placeholder="John Doe"
              type="text"
              id="name"
              name="name"
            ></input>
          </div>
          <div>
            <label htmlFor="email">E-mail</label>
            <input
              placeholder="john.doe@gmail.com"
              type="text"
              id="email"
              name="email"
            ></input>
          </div>
          <div>
            <label htmlFor="subject">Onderwerp</label>
            <input
              placeholder="onderwerp"
              type="text"
              id="subject"
              name="subject"
            ></input>
          </div>
        </LeftCol>
        <div>
          <label htmlFor="message">Bericht</label>
          <textarea
            placeholder="Jouw bericht"
            id="message"
            name="message"
          ></textarea>
        </div>
      </Columns>
      <ButtonContainer>
        {state.succeeded && <P>We hebben je bericht goed ontvangen! 🚀</P>}
        {state.submitting && (
          <BounceLoader color={"#2500D1"} size={20}></BounceLoader>
        )}
        <Button type="submit" disabled={state.submitting}>
          Verzenden
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  background: #fff;
  padding: 2rem;
  box-shadow: 0 0 50px rgba(37, 0, 209, 0.08);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  & label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }

  & input,
  & textarea {
    padding: 0.5rem;
    border: 2px solid ${(props) => props.theme.pageCoverBackground};
    /* margin-bottom: 1rem; */
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: ${(props) => props.theme.pageCoverBackground};
    }
  }

  & textarea {
    height: 180px;
    resize: none;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    & input {
      margin-bottom: 1rem;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default ContactForm;
