import React, { PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";

interface Props {}

const General = ({ children }: PropsWithChildren<Props>) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default General;

const theme = {
  primaryDefault: "#2500D1",
  rowPadding: "padding: 4rem 0",
  fontColor: "#000",
  pageCoverBackground: "#F5F4FD",
};
