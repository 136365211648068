import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Cta from "../UI/shared/ui-components/cta/Cta";
import Footer from "../UI/shared/ui-components/footer/Footer";
import Header from "../UI/shared/ui-components/functional/header/Header";
import General from "../UI/shared/ui-components/layout/General";
import Grid from "../UI/shared/ui-components/layout/Grid";
import PageCover from "../UI/shared/ui-components/layout/PageCover";
import H2 from "../UI/shared/ui-components/presentational/H2";
import Apps from "../UI/expertise/Apps";
import ThreeConfig from "../UI/expertise/ThreeConfig";
import SEO from "../UI/shared/ui-components/functional/seo/SEO";

const expertise = ({ data }) => {
  return (
    <General>
      <SEO title="Expertise | App laten maken Antwerpen | 5bars"></SEO>
      <Header></Header>
      <PageCover
        title="Expertise"
        subTitle="5bars is expert op het gebied van websites, apps en integraties met bestaande systemen. Lees hieronder meer over ons vakmanschap als je een app wil laten maken. "
        leftColComponent={() => (
          <StyledH2>
            “Tijdswinst is ons vak en technologie ons gereedschap”
          </StyledH2>
        )}
        rightColComponent={() => (
          <Image
            image={data.sanityImages.mainImage.asset.gatsbyImageData}
            alt="Pauw met veren open"
          ></Image>
        )}
      ></PageCover>
      <Apps></Apps>
      <ThreeConfig></ThreeConfig>
      <Grid>
        <Cta></Cta>
      </Grid>
      <Footer></Footer>
    </General>
  );
};

export default expertise;

export const query = graphql`
  query ExpertiseImageQuery {
    sanityImages(_id: { eq: "7e4870cd-a635-442d-a3ff-4655ccb5c13a" }) {
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
  @media only screen and (max-width: 1000px) {
    height: 250px;
  }
`;

const StyledH2 = styled(H2)`
  width: 450px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
