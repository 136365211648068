import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import HamburgerMenu from "react-hamburger-menu";

import Grid from "../../layout/Grid";
import Logo from "../../../../../svg/logo.svg";
import { useMediaQuery } from "@react-hook/media-query";

interface Props {}

const Header = (props: Props) => {
  const headerRef = useRef(null);
  const mobileQuery = useMediaQuery("only screen and (max-width: 768px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    console.log("mobile: ", mobileQuery);
  }, [mobileQuery]);

  useEffect(() => {
    const scrollFunc = (e) => {
      const scrollPos = window ? window.scrollY : 0;
      if (scrollPos !== 0) {
        headerRef.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
        headerRef.current.style.boxShadow = "0 0 10px rgba(0,0,0,0.2)";
        headerRef.current.style.padding = "0";
      } else {
        headerRef.current.style.backgroundColor = "rgba(255, 255, 255, 0)";
        headerRef.current.style.boxShadow = "0 0 10px rgba(0,0,0,0)";
        headerRef.current.style.padding = "1rem 0";
      }
    };

    document.addEventListener("scroll", scrollFunc);

    return () => {
      document.removeEventListener("scroll", scrollFunc);
    };
  }, [headerRef]);

  return (
    <HeaderContainer ref={headerRef}>
      <StyledGrid>
        <Link to="/">
          <StyledLogo isOpen={isMenuOpen}></StyledLogo>
        </Link>

        <StyledBurger>
          <HamburgerMenu
            isOpen={isMenuOpen}
            menuClicked={() => {
              setIsMenuOpen((isMenuOpen) => !isMenuOpen);
            }}
            color={isMenuOpen ? "#fff" : "#2500D1"}
            height={14}
            width={18}
          ></HamburgerMenu>
        </StyledBurger>

        <Nav isOpen={isMenuOpen} mobileQuery={mobileQuery}></Nav>
      </StyledGrid>
    </HeaderContainer>
  );
};

export default Header;

const StyledBurger = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 120;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const Nav = ({ isOpen, mobileQuery }) => {
  return mobileQuery ? (
    <Menu isOpen={isOpen}>
      <NavItem to="/">home</NavItem>
      <NavItem to="/cases">cases</NavItem>
      <NavItem to="/expertise">expertise</NavItem>
      <NavItem to="/contact">contact</NavItem>
    </Menu>
  ) : (
    <StyledNav>
      <NavItem to="/">home</NavItem>
      <NavItem to="/cases">cases</NavItem>
      <NavItem to="/expertise">expertise</NavItem>
      <NavItem to="/contact">contact</NavItem>
    </StyledNav>
  );
};

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 99;
  padding: 1rem 0;
  transition: padding 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
`;

const Menu = styled.nav<{ isOpen: boolean }>`
  display: none;
  position: fixed;
  transition: right 0.3s ease;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.isOpen ? "0" : "-100vw")};
  width: 100vw;
  z-index: 98;
  background: ${(props) => props.theme.primaryDefault};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

const StyledLogo = styled(Logo)<{ isOpen: boolean }>`
  transition: fill 0.3s ease;
  fill: ${(props) => (props.isOpen ? "#fff;" : props.theme.primaryDefault)};
  height: 5rem;
  width: auto;
  z-index: 99;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
    display: none;
  }
`;

const NavItem = styled(Link)`
  color: #000;
  text-decoration: none;
  font-weight: bold;
  margin: 0 2rem;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    display: block;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    width: 0;
    transition: width 0.3s ease;
    background-color: ${(props) => props.theme.primaryDefault};
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &[aria-current]:not([aria-current="false"]),
  &:hover {
    &:before {
      width: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    color: #fff;
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;

    &:before {
      content: normal;
    }
  }
`;
