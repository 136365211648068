import React from "react";
import styled from "styled-components";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Grid from "../shared/ui-components/layout/Grid";
import H2 from "../shared/ui-components/presentational/H2";
import P from "../shared/ui-components/presentational/P";
import ArrowLeft from "../../svg/arrow-left-solid.svg";
import { useMediaQuery } from "@react-hook/media-query";

interface Props {}

const Apps = (props: Props) => {
  const { web, mobile } = useStaticQuery(graphql`
    query ExpertiseImagesQuery {
      web: sanityImages(_id: { eq: "e844d5d9-19b0-46dc-9ca0-07df044b24dd" }) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      mobile: sanityImages(
        _id: { eq: "f9afa980-36c5-4c56-9d45-3fadeea35a24" }
      ) {
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const mobileQuery = useMediaQuery("only screen and (max-width: 768px)");

  return (
    <Section>
      <Grid>
        <H2>Custom ontwikkelde Applicaties</H2>
        <Divider></Divider>
        <CustomText>
          Wil jij een app laten maken? Wanneer er nog geen oplossing voor jouw
          specifieke probleem bestaat is 5bars de perfecte partner om een
          oplossing op maat te ontwikkelen.
        </CustomText>
      </Grid>
      <Grid>
        <CarouselContainer>
          <CarouselProvider
            className="carouselProvider"
            naturalSlideWidth={700}
            naturalSlideHeight={mobileQuery ? 1500 : 700}
            totalSlides={2}
          >
            <Slider className="carouselSlider">
              <Slide index={0}>
                <SlideComponent
                  image={web.mainImage.asset.gatsbyImageData}
                  title="Toegankelijke Web Apps"
                  heading="Op maat ontwikkelde applicaties die overal ter wereld beschikbaar zijn"
                  contentComponent={() => (
                    <div>
                      <P>
                        Web apps combineren de kracht van mobiele apps met de
                        mogelijkheden van desktop. Daarnaast zijn ze online te
                        bereiken (ook via je mobiele telefoon) en kunnen ze dus
                        overal ter wereld gebruikt worden. Indien nodig werken
                        onze web apps zelfs offline, of kan je ze op een
                        smartphone installeren alsof het mobiele apps zijn.
                      </P>
                      <P>
                        Daarnaast kunnen ze ook ten allen tijden geüpdate worden
                        zonder dat de gebruiker daar iets voor hoeft te doen.
                        Onze web apps zijn op z'n minst gezegd flexibel.
                      </P>
                    </div>
                  )}
                ></SlideComponent>
              </Slide>
              <Slide index={1}>
                <SlideComponent
                  image={mobile.mainImage.asset.gatsbyImageData}
                  title="Performante mobiele Apps"
                  heading="Om jouw €1.000.000 idee werkelijkheid te maken"
                  contentComponent={() => (
                    <div>
                      <P>
                        Soms heb je alle mogelijkheden van je smartphone nodig
                        om jouw idee te realiseren, of wil je de massa bereiken
                        via de play- en app-stores. Daarvoor bouwen we krachtige
                        mobiele applicaties met de nieuwste technologieën. We
                        werken met je samen vanaf het uitwerken van je idee tot
                        wanneer jouw applicatie in de app stores zit, en nog
                        lang daarna.
                      </P>
                    </div>
                  )}
                ></SlideComponent>
              </Slide>
            </Slider>
            <StyledBackButton>
              <StyledArrow></StyledArrow>
            </StyledBackButton>
            <StyledNextButton>
              <RightArrow></RightArrow>
            </StyledNextButton>
          </CarouselProvider>
        </CarouselContainer>
      </Grid>
    </Section>
  );
};

export default Apps;

const Section = styled.section`
  ${(props) => props.theme.rowPadding};
  padding-top: 0;
  max-width: 100vw;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Divider = styled.div`
  width: 80px;
  height: 5px;
  background-color: ${(props) => props.theme.pageCoverBackground};
  margin-bottom: 2rem;
`;

const CustomText = styled(P)`
  width: 450px;
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const CarouselContainer = styled.div`
  width: 700px;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StyledBackButton = styled(ButtonBack)`
  border: none;
  background: ${(props) => props.theme.primaryDefault};
  height: 60px;
  width: 60px;
  position: absolute;
  right: calc(60px + 1rem);
  top: 300px;
`;

const StyledNextButton = styled(ButtonNext)`
  border: none;
  background: ${(props) => props.theme.primaryDefault};
  height: 60px;
  width: 60px;
  position: absolute;
  right: 1rem;
  top: 300px;
`;

const StyledArrow = styled(ArrowLeft)`
  fill: #fff;
  height: 15px;
  width: auto;
`;

const RightArrow = styled(StyledArrow)`
  transform: rotate(180deg);
`;

const SlideComponent = ({ image, title, heading, contentComponent }) => {
  return (
    <SlideContainer>
      <Image image={image} alt="mobile and web apps"></Image>
      <ContentContainer>
        <h3>{title}</h3>
        <h4>{heading}</h4>
        {contentComponent()}
      </ContentContainer>
    </SlideContainer>
  );
};

const SlideContainer = styled.div`
  width: calc(700px - 1rem);
  margin-right: 1rem;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

const ContentContainer = styled.div`
  width: 400px;
  max-width: 400px;

  & h3 {
    font-size: 1rem;
  }

  & h4 {
    font-size: 1.6rem;
    margin: 1.2rem 0;
  }

  & p {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

const Image = styled(GatsbyImage)`
  height: 300px;
  width: 100%;
  margin-bottom: 60px;
`;
