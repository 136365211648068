import styled from "styled-components";

interface Props {}

const Grid = styled.div`
  width: 1100px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export default Grid;
