import React from "react";
import { Canvas } from "@react-three/fiber";
import Dots from "./Dots";
import { Effects } from "./Effects";

const PointCanvas = ({ zoom = 5 }) => {
  return (
    <Canvas
      orthographic
      camera={{ zoom: zoom }}
      resize={{ scroll: false }}
      style={{ height: "300px" }}
    >
      <color attach="background" args={["black"]}></color>
      <Dots></Dots>
      <Effects></Effects>
    </Canvas>
  );
};

export default PointCanvas;
