import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import BlockContent from "@sanity/block-content-to-react";

import Header from "../UI/shared/ui-components/functional/header/Header";
import Grid from "../UI/shared/ui-components/layout/Grid";
import Cta from "../UI/shared/ui-components/cta/Cta";
import Footer from "../UI/shared/ui-components/footer/Footer";
import General from "../UI/shared/ui-components/layout/General";
import PageCover from "../UI/shared/ui-components/layout/PageCover";
import H2 from "../UI/shared/ui-components/presentational/H2";
import SEO from "../UI/shared/ui-components/functional/seo/SEO";

const Case = (props) => {
  const { data = {} } = props;
  const { mainImage, client, _rawBody, shortText, title } = data.case || {};

  return (
    <General>
      <SEO title={`${title} | App laten maken Antwerpen | 5bars`}></SEO>
      <Header></Header>
      <PageCover
        title={client.name}
        subTitle="Getriggerd om jouw processen te automatiseren na het lezen van deze case? Laat een berichtje achter via de contact pagina en we contacteren je zo snel mogelijk! 🚀"
        leftColComponent={() => (
          <div>
            <H2>{shortText}</H2>
          </div>
        )}
        rightColComponent={() => (
          <Image
            image={mainImage.asset.gatsbyImageData}
            alt={`Afbeelding ${client.name}`}
          ></Image>
        )}
      ></PageCover>
      <Grid>
        <Article>
          <BlockContent blocks={_rawBody}></BlockContent>
        </Article>
      </Grid>
      <Grid>
        <Cta></Cta>
      </Grid>
      <Footer></Footer>
    </General>
  );
};

export default Case;

export const query = graphql`
  query CaseTemplateQuery($id: String!) {
    case: sanityCase(id: { eq: $id }) {
      title
      shortText
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      client {
        name
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
`;

const Article = styled.article`
  ${(props) => props.theme.rowPadding};
  padding-top: 0;
  width: 550px;
  margin-left: auto;
  margin-right: auto;

  & img {
    width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  & h1 {
    font-size: 2rem;
  }

  & p {
    font-size: 1rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    padding-bottom: 4rem;
  }
`;
