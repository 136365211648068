import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import P from "../../shared/ui-components/presentational/P";

interface Props {
  testimonial: { quote: string; name: string; testimonial: string };
  onFinishTimer: () => void;
}

const TIME = 15;

const Testimonial = ({ testimonial, onFinishTimer }: Props) => {
  // This is time in seconds
  const barRef = useRef(null);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    let timer;
    setTrigger((trigger) => !trigger);
    timer = setTimeout(() => {
      onFinishTimer();
    }, TIME * 1000);
    return () => clearTimeout(timer);
  }, [testimonial]);

  return (
    <StyledTestimonialWrapper>
      <h3>"{testimonial.quote}"</h3>
      <strong>{testimonial.name}</strong>
      <P>{testimonial.testimonial}</P>
      <ProgressBar ref={barRef} trigger={trigger}></ProgressBar>
    </StyledTestimonialWrapper>
  );
};

export default Testimonial;

const StyledTestimonialWrapper = styled.div`
  padding: 3rem;
  position: relative;
  overflow: hidden;
  min-height: 250px;
  box-shadow: 0 0 99px rgba(37, 0, 209, 0.1);
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;

  & h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  & strong {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const slideLeft = keyframes`
    from {
        left: 0;
    }
    to {
        left: -100%;
    }
`;
const slideLeft2 = keyframes`
    0% {
        left: 0%;
    }
    100% {
        left: -100%;
    }
`;

const ProgressBar = styled.div<{ trigger: boolean }>`
  background-color: ${(props) => props.theme.primaryDefault};
  height: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: ${(props) => {
    return props.trigger
      ? css`
          ${slideLeft} ${TIME}s linear forwards
        `
      : css`
          ${slideLeft2} ${TIME}s linear forwards
        `;
  }};
`;
