import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Grid from "../shared/ui-components/layout/Grid";
import Case from "../shared/ui-components/singles/case/Case";

interface Props {}

const CasesList = (props: Props) => {
  const { allSanityCase } = useStaticQuery(graphql`
    query AllCasesQuery {
      allSanityCase {
        nodes {
          color
          shortText
          title
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Grid>
        <CasesRow>
          {allSanityCase.nodes.map((cs, i) => {
            return (
              <Case
                key={i}
                color={cs.color}
                image={cs.mainImage.asset.gatsbyImageData}
                description={cs.shortText}
                slug={cs.slug.current}
                customer={cs.title}
              ></Case>
            );
          })}
        </CasesRow>
      </Grid>
    </Section>
  );
};

export default CasesList;

const Section = styled.section`
  ${(props) => props.theme.rowPadding};
  padding-bottom: 8rem;
`;

const CasesRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
