import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";

import { gsap, Elastic } from "gsap";

interface Props {
  index: number;
}

const Bar = ({ index }: Props) => {
  const barRef = useRef(null);

  useLayoutEffect(() => {
    const baseline = window ? window.innerHeight : 0;
    barRef.current.style.top = `${baseline / 5 + (5 - index) * 100}px`;
    const onMouseMove = (e) => {
      if (barRef) {
        const baseline = window ? window.innerHeight : 0;
        const diff = Math.abs(
          barRef.current.getBoundingClientRect().left + 1200 / 9 / 2 - e.clientX
        );
        gsap.to(`.bar-${index}`, 2, {
          top: baseline - diff,
          ease: Elastic.easeOut.config(1, 0.3),
        });
      }
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [barRef, index]);

  return (
    <StyledBar>
      <InnerBar ref={barRef} className={`bar-${index}`}></InnerBar>
    </StyledBar>
  );
};

export default Bar;

const StyledBar = styled.div`
  width: 100%;
  position: relative;
`;

const InnerBar = styled.div`
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(46, 0, 255, 0.49),
    rgba(37, 0, 209, 0.07)
  );
  z-index: -1;
  width: 100%;
  bottom: 0;
  filter: blur(3px);
  opacity: 0.4;
`;
