import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  typification?: "default" | "sub" | "bordered";
  white?: boolean;
  [key: string]: any;
}

const Button = ({
  children,
  typification = "default",
  white = false,
  ...rest
}: PropsWithChildren<Props>) => {
  switch (typification) {
    case "bordered":
      return (
        <BorderedButton {...rest} white={white}>
          {children}
        </BorderedButton>
      );
    default:
      return (
        <StyledButton {...rest} white={white}>
          {children}
        </StyledButton>
      );
  }
};

export default Button;

const StyledButton = styled.button<{ white?: boolean }>`
  padding: 0.8rem 2rem;
  background: ${(props) => (props.white ? "#fff" : props.theme.primaryDefault)};
  color: ${(props) => (props.white ? props.theme.primaryDefault : "#fff")};
  font-weight: bold;
  transition: opacity 0.3s ease, background 0.3s ease;
  border: none;
  text-transform: uppercase;

  &:disabled {
    background: lightgrey;
    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const BorderedButton = styled(StyledButton)`
  background: transparent;
  color: ${(props) => (props.white ? "#fff" : props.theme.primaryDefault)};
  border: 1px solid
    ${(props) => (props.white ? "#fff" : props.theme.primaryDefault)};
`;
