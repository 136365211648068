import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Button from "../shared/ui-components/functional/form/Button";
import Grid from "../shared/ui-components/layout/Grid";
import Row from "../shared/ui-components/layout/Row";
import Bars from "./bars/Bars";

interface Props {}

const Cover = (props: Props) => {
  return (
    <StyledPageContainer>
      <StyledGrid>
        <Slogan>Keer terug naar de kern van je werk.</Slogan>
        <Sub>
          5bars maakt apps die voor ù werken. Zo krijg je meer tijd om écht met
          je zaak bezig te zijn.
        </Sub>
        <Row gap={1} pos={"center"}>
          <StyledLink to="#philosophy">
            <Button typification="bordered">Meer info</Button>
          </StyledLink>
          <StyledLink to="contact">
            <Button>Contact</Button>
          </StyledLink>
        </Row>
        <Bars></Bars>
      </StyledGrid>
    </StyledPageContainer>
  );
};

export default Cover;

const Slogan = styled.p`
  font-size: 2.5rem;
  margin: 1rem 0;
  font-weight: bold;
`;

const StyledPageContainer = styled.section``;

const StyledGrid = styled(Grid)`
  position: relative;
  height: 100vh;
  min-height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;
    min-height: 600px;
    padding: 0 1rem;
  }
`;

const Sub = styled.h1`
  font-weight: normal;
  margin-bottom: 2rem;
  max-width: 400px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
