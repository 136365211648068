import React from "react";
import styled from "styled-components";

import Grid from "./Grid";
import P from "../presentational/P";
import Bars from "../../../../svg/bars.svg";

interface Props {
  title: string;
  subTitle: string;
  leftColComponent: () => any;
  rightColComponent: () => any;
}

const PageCover = ({
  title,
  subTitle,
  leftColComponent,
  rightColComponent,
}: Props) => {
  return (
    <Section>
      <TitleRow>
        <Grid>
          <Title>
            <HighBar></HighBar>
            <TitleSubTitleContainer>
              <H1>{title}</H1>
              <SubTitleContainer>
                <LowBar></LowBar>
                <P>{subTitle}</P>
              </SubTitleContainer>
            </TitleSubTitleContainer>
          </Title>
        </Grid>
      </TitleRow>
      <Grid>
        <ContentRow>
          <LeftCol>
            {leftColComponent()}
            <StyledBars></StyledBars>
          </LeftCol>
          <RightCol>{rightColComponent()}</RightCol>
        </ContentRow>
      </Grid>
    </Section>
  );
};

export default PageCover;

const Section = styled.section``;

const TitleRow = styled.div`
  background-color: ${(props) => props.theme.pageCoverBackground};
  ${(props) => props.theme.rowPadding};
  padding-top: 8rem;
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const Bar = styled.div`
  background-color: ${(props) => props.theme.primaryDefault};
  width: 1.8rem;
  margin-right: 0.8rem;
`;

const HighBar = styled(Bar)`
  height: 10rem;
`;

const LowBar = styled(Bar)`
  height: 100%;
`;

const TitleSubTitleContainer = styled.div`
  display: grid;
  height: 10rem;
  grid-template-rows: auto 1fr;
  width: calc(100% - 2.6rem);
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.primaryDefault};
  font-size: 3rem;
  font-weight: bold;
  margin: 1rem 0;

  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & p {
    width: 400px;
    color: #3b3b3b;
  }

  @media only screen and (max-width: 768px) {
    & p {
      width: calc(100% - 4.4rem);
      font-size: 0.8rem;
    }
  }
`;

const StyledBars = styled(Bars)`
  fill: #faf9fe;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: auto;
  z-index: -1;

  @media only screen and (max-width: 768px) {
    width: 250px;
    left: calc(50% - 125px);
  }
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  grid-gap: 1rem;
  ${(props) => props.theme.rowPadding};

  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 2rem;
  }
`;

const LeftCol = styled.div`
  position: relative;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 3rem 0;
  }
`;

const RightCol = styled.div`
  margin-top: -15rem;
  @media only screen and (max-width: 1000px) {
    margin-top: -6rem;
    width: 100%;
  }
`;
