import * as React from "react";

import Cover from "../UI/home/Cover";
import Cta from "../UI/shared/ui-components/cta/Cta";
import Footer from "../UI/shared/ui-components/footer/Footer";
import General from "../UI/shared/ui-components/layout/General";
import Grid from "../UI/shared/ui-components/layout/Grid";
import Header from "../UI/shared/ui-components/functional/header/Header";
import Philosophy from "../UI/home/Philosophy";
import Expertise from "../UI/home/expertise/Expertise";
import Cases from "../UI/home/cases/Cases";
import Testimonials from "../UI/home/testimonial/Testimonials";
import SEO from "../UI/shared/ui-components/functional/seo/SEO";

// markup
const IndexPage = () => {
  return (
    <General>
      <SEO title="App development Antwerpen | App laten maken Antwerpen"></SEO>
      <Header></Header>
      <Cover></Cover>
      <Cases></Cases>
      <Philosophy></Philosophy>
      <Expertise></Expertise>
      <Testimonials></Testimonials>
      <Grid>
        <Cta></Cta>
      </Grid>
      <Footer></Footer>
    </General>
  );
};

export default IndexPage;
