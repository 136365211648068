import React from "react";
import styled from "styled-components";
import Grid from "../shared/ui-components/layout/Grid";
import H2 from "../shared/ui-components/presentational/H2";
import P from "../shared/ui-components/presentational/P";

interface Props {}

const ThreeConfig = (props: Props) => {
  return (
    <Section>
      <StyledGrid>
        <H2>3D Configurators</H2>
        <P>
          3D product configurators zijn de ideale manier om uw klanten sneller
          te overtuigen. Niets heeft zoveel overtuigingskracht als de
          mogelijkheid om jouw product helemaal aan de eigen wensen van de klant
          aan te passen. En dat allemaal vanop je website of webshop.
        </P>
      </StyledGrid>
    </Section>
  );
};

export default ThreeConfig;

const Section = styled.section`
  background-color: ${(props) => props.theme.pageCoverBackground};
  ${(props) => props.theme.rowPadding};
  margin-bottom: 4rem;
`;

const StyledGrid = styled(Grid)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    width: 400px;
  }
`;
